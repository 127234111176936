import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`



const StyledDiv = styled.div`
  background-color: #C9DDFF11;
  color: #C9DDFF;
  border-color: #C9DDFF;
  text-decoration: none;
  border-style: solid;
  outline: none;
  text-align: center;
  border-width: 1px;
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 1.25px;
  padding: 16px;
  cursor: pointer;
  transition-duration: 0.4s;
  &:hover {
    background-color: #C9DDFF;
    color: #424242;
  }
`;

const StyledLink = styled(StyledDiv).attrs({
  as: "a"
})`

`

const StyledButton = styled(StyledDiv).attrs({
  as: "button"
})`
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`


const Button = ({text, style, to, leftComponent, rightComponent, disabled}) => (
  <StyledLink target='_blank' href={to} style={{...style}} disabled={disabled}>
    <Row>
      <div style={{flex: 1}}>
        {leftComponent}
      </div>
      {text}
      <div style={{flex: 1}}>
        {rightComponent}
      </div>
    </Row>
  </StyledLink>
);

export default Button;
