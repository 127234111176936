import { Link } from "gatsby";
import styled from 'styled-components';

const StyledLink = styled(Link)`
  position: relative;
  color: white;
  text-decoration: none;
  outline: none;
  user-select: none;
  &:visited {
    color: ${props => props.visitedStyle && props.visitedStyle.color ? props.visitedStyle.color : 'white'};
  }
  &::after {
    position: absolute;
    opacity: ${props => props.selected ? 1 : 0};
    bottom: -4px;
    left: 0px;
    width: 100%;
    height: 2px;
    content: '';
    background-color: #C9DDFF;
  }
  `;

  export default StyledLink;
