import PropTypes from "prop-types";
import React from "react";
import styled from 'styled-components';
import Button from './button';
import {AiFillLinkedin} from 'react-icons/ai';
import {MdMenu, MdClose} from 'react-icons/md';
import Link from './link';

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  background: ${props => !props.transparent || props.isOpen ? '#181818' : '#18181800'};
  opacity: ${props => props.showHeader ? 1 : 0};
  pointer-events: ${props => props.showHeader ? 'auto' : 'none'};
  transition-duration: 0.4s;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpenMenu = styled(Col)`
  background-color: #181818;
  position: fixed;
  align-items: center;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
`

const Menu = ({children, isMobile, isOpen, onClose, onOpen}) => {
  return (
    <div>
      {!isMobile &&
        <Row style={{alignItems: 'center'}}>
          {children}
        </Row>
      }
      {isMobile &&
        (isOpen ? <MdClose size={48} onClick={onClose}/> : <MdMenu size={48} onClick={onOpen}/>)
      }
      {isMobile && isOpen &&
        <OpenMenu isOpen={isOpen} onClick={(e) => {
          // when a child is clicked, close the menu
          onClose();
        }}>
          {children}
        </OpenMenu>
      }
    </div>
  );
}



class Header extends React.Component {

  prevScrollY = 0;
  state = {
    showHeader: true,
    isMobile: false,
    isOpen: false
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.shouldShowHeader);
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.shouldShowHeader);
    window.removeEventListener('resize', this.onResize);
  }

  shouldShowHeader = (e) => {
    const scrollY = window.scrollY;
    if ((scrollY <= 0 || scrollY < this.prevScrollY)){
      if (!this.state.showHeader){
        this.setState({showHeader: true})
      }
    }
    else if (this.state.showHeader){
      this.setState({showHeader: false})
    }
    this.prevScrollY = scrollY;
  }

  onResize = () => {
    const w = window.innerWidth;
    if (w < 900){
      this.setState({
        isMobile: true
      })
    }
    else if (this.state.isMobile && w >= 900){
      this.setState({
        isMobile: false,
        isOpen: false
      })
    }
  }

  render() {
    const {siteTitle, location, transparent} = this.props;
    const currentPath = location ? location.pathname : null;
    return (
      <StyledHeader showHeader={this.state.showHeader || this.state.isOpen} transparent={transparent} isOpen={this.state.isOpen}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: `24px`,
            paddingLeft: 48,
            paddingRight: 48
          }}
        >
          <Link
            to="/"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
          <img src={require('../images/rebase_logo_3.png')} style={{height: 28,  margin: 0, padding: 0}}/>
          <h3 style={{ margin: 0, padding: 0, marginLeft: 8, color: '#C9DDFF', fontWeight: 300}}>
              {siteTitle}
          </h3>
        </Link>
        <div style={{flex: 1}}/>
        <Menu
          isMobile={this.state.isMobile}
          isOpen={this.state.isOpen}
          onClose={() => this.setState({isOpen: false})}
          onOpen={() => this.setState({isOpen: true})}
          >
          <Link to='/' selected={currentPath === '/'}
            style={{
              marginLeft: this.state.isOpen ? 0 : 32,
              fontSize: this.state.isOpen ? 32 : 16,
              marginTop: this.state.isOpen ? 64 : 0,
            }}>
            <span>Home</span>
          </Link>
          <Link to='/team' selected={currentPath === '/team'}
            style={{
              marginLeft: this.state.isOpen ? 0 : 32,
              marginTop: this.state.isOpen ? 32 : 0,
              fontSize: this.state.isOpen ? 32 : 16
            }}>
            Team
          </Link>
          <Link
            to='https://docs.rebase.energy/sdk/v1/'
            target='_blank'
            style={{
              marginLeft: this.state.isOpen ? 0 : 32,
              marginTop: this.state.isOpen ? 32 : 0,
              fontSize: this.state.isOpen ? 32 : 16
            }}>
            Docs
          </Link>
          <Link
            to='https://www.linkedin.com/company/rebaseenergy/'
            target='_blank'
            style={{
              marginLeft: this.state.isOpen ? 0 : 32,
              marginRight: this.state.isOpen ? 0 : 64,
              marginTop: this.state.isOpen ? 32 : 0,
            }}>
            <AiFillLinkedin size={this.state.isOpen ? 48 : 24}/>
          </Link>
          <Button
            style={{
              padding: 12,
              marginRight: this.state.isOpen ? 0 : 16,
              marginTop: this.state.isOpen ? 32 : 0,
              fontSize: this.state.isOpen ? 18 : 13,
              width: this.state.isOpen ? 240 : 100
            }}
            text='LOGIN'
            to='https://dashboard.rebase.energy'
          />
          <Button
            style={{
              padding: 12,
              marginTop: this.state.isOpen ? 32 : 0,
              fontSize: this.state.isOpen ? 18 : 13,
              width: this.state.isOpen ? 240 : 100
            }}
            text='SIGN UP'
            to='https://dashboard.rebase.energy/auth/signup/trial'
          />
        </Menu>
        </div>
      </StyledHeader>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
