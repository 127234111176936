/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

import Header from "./header"
import "./layout.css";
import Link from './link';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`




const Layout = ({ children, location, transparentHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        transparent={transparentHeader}
        location={location} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto 128px`,
        }}
      >
        <main>{children}</main>

      </div>
      <footer style={{
        padding: 48,
        height: 120
      }}>
      <Row style={{alignItems: 'center', marginBottom: 16}}>
        <img src={require('../images/partners/EU.png')} style={{height: 50}}/>
        <p style={{fontSize: 13, marginLeft: 16}}>
          This is part of the AI4Cities project that has received funding from the European Union’s Horizon 2020 Research and Innovation Programme.
        </p>
      </Row>
      <Row>
        <p style={{color: '#C9DDFF'}}>
          ©{(new Date()).getFullYear()} REBASE ENERGY. All rights reserved.
        </p>
        <div style={{flex: 1}}/>
        <Link to='privacy-policy'>
          <p style={{color: '#C9DDFF'}}>
            Privacy Policy
          </p>
        </Link>
      </Row>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
